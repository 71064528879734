






import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Chart from 'chart.js';
import { analysisStores } from '@/store/poultry/analysis/index';
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';
import { AnalysisData, ScoreAnalysis, LineChartProperties } from '@/models/poultry/analysis.model';
import { reportTypeStores } from '@/store/poultry/reportType';
import { phase2_chart, phase3_chart, phase4_low_chart, phase4_low__horizontal_chart, phase4_eba_horizontal_chart, phase4_eba_chart } from '@/utils/chart.config';
import { phase2_scheme, phase3_scheme, phase4_scheme } from '@/utils/chart.color';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BarChartProperties } from '@/models/poultry/analysis.model';
import { ReportTypeIds } from '@/constants';

@Component({
  components: {},
})
export default class ResultsAnalysisChartBody extends Vue {
  store = analysisStores.results.list;
  reportTypeDetailStore = reportTypeStores.detail;
  analysisChart: Chart = new Chart('', {})
  chartKey = Math.random();
  chartColor = this.chartColors;

  @Watch('store.chartStyle')
  updateGraph() {
    this.store.fetchAll();
  }

  get results(): AnalysisData[] {
    return this.store.results;
  }

  get chartColors(){
    if (this.isIpmReport){
      return phase3_scheme;
    }

    if (this.isLowReport || this.isEbaReport || this.isPeaReport){
      return phase4_scheme;
    }

    return phase2_scheme;
  }

  get monthResults() {
    const startDate = this.store.startDate;
    const endDate = this.store.endDate;

    let dates: Dayjs[] = [];
    let dateObj = dayjs(startDate!);
    const startDateObj = dayjs(startDate!)
      .startOf('month')
      .endOf('day');
    const endDateObj = dayjs(endDate!)
      .startOf('month')
      .endOf('day');

    while (dateObj.isBefore(endDateObj)) {
      dates.push(dateObj);
      dateObj = dateObj.add(1, 'month');
    }
    return dates;
  }

  get months(): string[] {
    return this.monthResults.map((dateObj) => dateObj.format('MMM YY'));
  }

  get isIpmReport(){
    return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.ipm;
  }

  get isLowReport(){
    return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.labOnWheel;
  }

  get isEbaReport(){
    return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.eba;
  }

  get isPeaReport(){
    return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.pea;
  }

  scores(result: AnalysisData) {
    let scoresArr: number[] = [];
    let data = result.data;

    data.forEach((data) => {
      scoresArr.push(data.score);
    });

    return scoresArr;
  }

  get crudeScale() {
    const months = this.months ? this.months.length : 1;
    const datasets = this.dataSets ? this.dataSets.length : 1;
    const paddings = 28 * months;
    const bars = 20 * datasets * months;
    const base = 240;
    return paddings + bars + base;
  }

  get dataSets() {
    // Return nothing if d response is empty
    if (!this.results) return;

    // Check whether every items in response have empty value
    const isEmpty = this.results.every((result) => !Boolean(result.data));
    if (isEmpty) return;

    if ((this.isIpmReport || this.isLowReport || this.isEbaReport || this.isPeaReport) && this.store.chartStyle === 'horizontalBar') {
      const resultArr: BarChartProperties[] = [];

      this.results.forEach((result, i) => {
        resultArr.push({
          label: result.name,
          data: this.scores(result),
          backgroundColor: this.chartColor[i % this.chartColor.length],
          barThickness: 19
        });
      });

      return resultArr;
    } else {
      const resultArr: LineChartProperties[] = [];

      this.results.forEach((result, i) => {
        resultArr.push({
          label: result.name,
          data: this.scores(result),
          backgroundColor: 'transparent',
          borderColor: this.chartColor[i % this.chartColor.length],
          pointBackgroundColor: this.chartColor[i % this.chartColor.length],
          pointBorderColor: this.chartColor[i % this.chartColor.length],
          pointBorderWidth: 1,
          borderWidth: 2,
        });
      });

      return resultArr;
    }
  }

  get chartData() {
    if ((this.isIpmReport || this.isLowReport || this.isEbaReport || this.isPeaReport) && this.store.chartStyle === 'horizontalBar') {
      let horizontalChartConfig = phase3_chart;

      if (this.isLowReport){
        horizontalChartConfig = phase4_low__horizontal_chart;
      }

      if (this.isEbaReport || this.isPeaReport){
        horizontalChartConfig = phase4_eba_horizontal_chart;
      }
      
      return {
        ...horizontalChartConfig,
        data: {
          labels: this.months,
          datasets: this.dataSets,
        },
      };
    } else {
      let chartConfig = phase2_chart;

      if (this.isLowReport){
        chartConfig = phase4_low_chart;
      }

      if (this.isEbaReport || this.isPeaReport){
        chartConfig = phase4_eba_chart;
      }
      
      return {
        ...chartConfig,
        data: {
          labels: this.months,
          datasets: this.dataSets,
        },
      }
    }
  }

  @Watch('results')
  async onResultsChanged() {
    this.chartKey = Math.random();
    await this.$nextTick();
    this.createChart('analysisChart', this.chartData);
  }

  createChart(chartId: any, chartData: any) {
    const ctx = document.getElementById(chartId) as HTMLCanvasElement;
    this.analysisChart = new Chart(ctx, {
      plugins: (this.isIpmReport || this.isLowReport || this.isEbaReport || this.isPeaReport) ? [ChartDataLabels] : undefined,
      type: chartData.type,
      data: chartData.data,
      options: chartData.options,
    });
  }

  mounted() {
    Chart.plugins.register(ChartDataLabels);
    this.createChart('analysisChart', this.chartData);

    if (window.matchMedia) {
      let mediaQueryList = window.matchMedia('print');
      mediaQueryList.addListener((mql) => {
        if (mql.matches) {
          this.analysisChart.resize();
        }
      });
    }

    window.onbeforeprint = this.analysisChart.resize
  }
}
