















































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { analysisStores } from '@/store/poultry/analysis';
import SortIndicator from '@/components/poultry/SortIndicator.vue';
import { ReportTypeIds, ReportComparisonType } from '@/constants';

@Component({
  components: {
    SortIndicator
  },
})
export default class ResultsAnalysisTableHeader extends Vue {
  store = analysisStores.results.list;

  get categoryType() {
    let title = this.store.categoryType;

    if (this.isBetweenEbaHatchersComparison || this.isBetweenPeaHatchersComparison) {
      title = 'Hatchery';
    }

    if (this.isBetweenTraysComparison) {
      title = 'Tray'; 
    }
    return title
  }

  get isBetweenPeaHatchersComparison(){
    return this.store.analysisType === ReportComparisonType.betweenPeaHatchers;
  }

  get isBetweenEbaHatchersComparison(){ 
    return this.store.analysisType === ReportComparisonType.betweenHatchers;
  }

  get isBetweenTraysComparison(){
    return this.store.analysisType === ReportComparisonType.betweenTrays;
  }

  get isBetweenFarmsComparison(){
    return this.store.analysisType === ReportComparisonType.betweenFarm;
  }

  get analysisType() {
    return this.store.analysisType;
  }

  sort(sortType: string) {
    this.store.setSortType(sortType);
  }

  get currentSort() {
    return this.store.currentSort;
  }

  get currentSortOrder() {
    return this.store.currentSortOrder.reverse;
  }
}
