











































import { Component, Vue } from 'vue-property-decorator';
import { analysisStores } from '@/store/poultry/analysis';
import dayjs from 'dayjs';
import { ReportTypeIds, ReportComparisonType } from '@/constants';

@Component({
  components: {}
})
export default class ResultsAnalysisChartHeader extends Vue {
  store = analysisStores.results.list;
  date: string | null = null;
	notExporting = true;

  get results() {
    return this.store.results;
  }

  get categoryType() {
    return this.store.categoryType;
  }

  get lesionType(){
    let lesion = this.store.lesionType ? this.store.lesionType.toUpperCase() : '';

    if (this.isCompareBetweenPeaHatchers){
      lesion = this.store.peaLesionType? this.store.peaLesionType.toUpperCase() : '';
    }

    return lesion;
  }

  get startDate() {
    let date: any = this.store.startDate;
    return dayjs(date).format('MMM YYYY');
  }

  get endDate() {
    let date: any = this.store.endDate;
    return dayjs(date).format('MMM YYYY');
  }

  get clientName() {
    return this.store.clientName;
  }

  get analysisType() {
    return this.store.analysisType;
  }

  get chartStyle() {
    return this.store.chartStyle;
  }

  get reportTypeId() {
    return Number(this.store.reportTypeId);
  }

  get isIpmReport(){
    return String(this.store.reportTypeId) === ReportTypeIds.ipm;
  }

  get isLowReport(){
    return String(this.store.reportTypeId) === ReportTypeIds.labOnWheel;
  }

  get isEbaReport(){
    return String(this.store.reportTypeId) === ReportTypeIds.eba;
  }

  get isPeaReport(){
    return String(this.store.reportTypeId) === ReportTypeIds.pea;
  }

  get isCompareBetweenClient(){
    return this.analysisType === ReportComparisonType.betweenClient;
  }

  get isCompareBetweenFarm(){
    return this.analysisType === ReportComparisonType.betweenFarm;
  }

  get isCompareBetweenComplex(){
    return this.analysisType === ReportComparisonType.betweenComplexes;
  }

  get isCompareBetweenEbaHatchers(){
    return this.analysisType === ReportComparisonType.betweenHatchers;
  }

  get isCompareBetweenTrays(){
    return this.analysisType === ReportComparisonType.betweenTrays;
  }

  get isCompareBetweenPeaHatchers(){
    return this.analysisType === ReportComparisonType.betweenPeaHatchers;
  }

  get comparisonTitleName(){
    let title = 'audit scoring';

    if (this.isLowReport){
      title = 'risk level result';
    }

    if (this.isEbaReport || this.isPeaReport){
      title = 'Lesion percentage'
    }

    return title;
  }

  handleExportButtonClicked() {
		this.notExporting = false;
		setTimeout(() => {
			window.print();
			this.notExporting = true;
		}, 50);
  }

  updateChartStyle(style: string) {
    this.store.setChartStyle(style)
  }
}
