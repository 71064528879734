






import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import ResultsAnalysisChartHeader from './ResultsAnalysisChartHeader.vue';
import ResultsAnalysisChartBody from './ResultsAnalysisChartBody.vue';

@Component({
  components: {
    ResultsAnalysisChartHeader,
    ResultsAnalysisChartBody,
  },
})
export default class ResultsAnalysisChart extends Vue {}
