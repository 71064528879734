








import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import ResultsAnalysisTableHeader from './ResultsAnalysisTableHeader.vue';
import ResultsAnalysisTableBody from './ResultsAnalysisTableBody.vue';

@Component({
  components: {
    ResultsAnalysisTableHeader,
    ResultsAnalysisTableBody
  }
})
export default class ResultsAnalysisTable extends Vue {
}
