














import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import ResultsAnalysisPageHeader from './ResultsAnalysisPageHeader.vue';
import EmptyAnalysisPlaceholder from '../EmptyAnalysisPlaceholder.vue';
import ResultsAnalysisChart from './ResultsAnalysisChart/ResultsAnalysisChart.vue';
import ResultsAnalysisTable from './ResultsAnalysisTable/ResultsAnalysisTable.vue';
import { analysisStores } from '@/store/poultry/analysis';

@Component({
  components: {
    ResultsAnalysisPageHeader,
    ResultsAnalysisChart,
    EmptyAnalysisPlaceholder,
    ResultsAnalysisTable,
  },
})
export default class ResultsAnalysis extends Vue {
  store = analysisStores.results.list;

  created() {
    this.store.setResultsCreated(false);
  }

  handleExportButtonClicked() {
    window.print();
  }

  get isResultsCreated() {
    return this.store.isCreated;
  }
}
