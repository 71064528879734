
























































































































































































































































































































































































































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import DropdownCompare from '@/components/poultry/DropdownCompare.vue';
import DropdownCompareHatchery from '@/components/poultry/DropdownCompareHatchery.vue';
import Dropdown from '@/components/poultry/Dropdown.vue';
import DropdownFilter from '@/components/poultry/DropdownFilter.vue';
import DropdownFilterSearchBar from '@/components/poultry/DropdownFilterSearchBar.vue';
import { dropdownStores } from '@/store/poultry/dropdowns';
import { Region } from '@/models/poultry/country.model';
import { clientStores } from '@/store/poultry/client/index';
import { ClientResponse } from '@/models/poultry/client.model';
import { farmStores } from '@/store/poultry/farm';
import { auditorStores } from '@/store/poultry/auditor/index';
import { FarmResponse } from '@/models/poultry/farm.model';
import { AuditorResponse } from '@/models/poultry/auditor.model';
import VueMonthlyPicker from 'vue-monthly-picker';
import { analysisStores } from '@/store/poultry/analysis/index';
import ClientNameFilter from '@/views/poultry/Reports/ReportTableFilter/ClientNameFilter.vue';
import moment from 'moment'; // TODO: Need to use moment as the Month Picker use moment lib
import DropdownFilterAlt from '@/components/poultry/DropdownFilterAlt.vue';
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { reportTypeStores } from '@/store/poultry/reportType';
import { ReportTypeIds, ReportComparisonType } from '@/constants';

interface NestedDict {
  [key: string]: Array<{text: string, value: string}>;
}

@Component({
  components: {
    DropdownCompare,
    DropdownCompareHatchery,
    DropdownFilterSearchBar,
    DropdownFilter,
    DropdownFilterAlt,
    ClientNameFilter,
    Dropdown,
    VueMonthlyPicker,
  },
})
export default class ResultsAnalysisPageHeader extends Vue {
  defaultFormat = 'MM/YYYY';
  monthList = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  clientStore = clientStores.list;
  clientDetailStore = clientStores.details;
  farmStore = farmStores.list;
  farmDetailStore = farmStores.details;
  auditorStore = auditorStores.list;
  auditorDetailStore = auditorStores.details;
  countryStore = dropdownStores.country;
  analysisStore = analysisStores.results.list;
  reportTypeDetailStore = reportTypeStores.detail;
  isBetweenOptions = false;
  defaultAnalysisType = this.isEbaReport ? ReportComparisonType.betweenFarm : ReportComparisonType.betweenClient;
  clientNameData: Object[] = [];
  ageOfFlockData: Object[] = [];

  isAuditTypeSelected = false;
  isSectionTypeSelected = false;
  isLesionTypeSelected = false;
  isPeaLesionTypeSelected = false;
  isFilteredFarmSelected = false;

  // Init type for ref
  @Ref() resetSelectedData!: HTMLFormElement;
  @Ref() activeSelectedDropdown!: any;

  result: any = {
    clientIds: [],
    categoryType: null,
    filteredData: [],
    fromMonth: null,
    toMonth: null,
    auditType: 'all',
    sectionType: 'all',
    lesionType: 'early',
    daysOfFlock: 'null',
    weeksOfFlock: 'null',
    peaLesionType: 'airsac',
    trayNumbers:[]
  };

  //This for temporary days and weeks of flock
  daysOfFlockDropdown = [
    { text: 'Day(s)', value: 'null'}
  ];

  weeksOfFlockDropdown = [
    { text: 'Week(s)', value: 'null'}
  ];

  //This is for temporary tray number
  trayNumberDropdown: DropdownOption[] = [];

  // This one would be a fixed value
  categoryType = [
    { text: 'State', value: 'state' },
    { text: 'Farm', value: 'farm' },
    { text: 'Auditor', value: 'auditor' },
  ];

  ebaCategoryType = [
    { text: 'Farm', value: 'farm' }
  ]

  hatcheryCategoryType = [
    { text: 'State', value: 'state' },
    { text: 'Auditor', value: 'auditor' },
  ];

  // For IPM
  ipmSectionDictionary: NestedDict = {
    'flyManagement': [
      {text: 'All sections', value: 'all'},
      {text: 'Adult Flies', value: 'adultFlies'},
      {text: 'Larvae and Pupae Phase', value: 'larvaeAndPupaePhase'},
      {text: 'Flies Excrements, Vomits Spots & Additional Risk Points', value: 'fliesExcrements'}
    ],
    'rodentsControl': [
      {text: 'All sections', value: 'all'},
      {text: 'Feed Storage Area & Barns', value: 'feedStorageAreaAndBarns'},
      {text: 'Outside The Warehouse and The Barns', value: 'outsideTheWarehouseAndTheBarns'},
      {text: 'Evaluation of Additional Aspects', value: 'evaluationOfAdditionalAspects'}
    ],
    'darklingBeetlesControl': [
      {text: 'All sections', value: 'all'},
      {text: 'Presence of Mobile Forms in the Litter', value: 'presenceOfMobileFormsInTheLitter'},
      {text: 'Presence of Mobile Forms in other Area / Structures', value: 'presenceOfMobileFormsInOtherAreaOrStructures'},
    ],
    'all': [
      {text: 'All sections', value: 'all'},
    ],
  }

  @Watch('reportTypeDetailStore.reportTypeId')
  resetPage() {
    if (this.reportTypeDetailStore.reportTypeId && Number(this.reportTypeDetailStore.reportTypeId) > 0) {
      if (this.isWaterReport || this.isInjectableReport || this.isHatcheryReport ||
          this.isCndReport || this.isIpmReport || this.isLowReport || this.isPeaReport) {
        this.isBetweenOptions = false;
        this.defaultAnalysisType = ReportComparisonType.betweenClient;
      }

      if (this.result && this.result.clientIds) {
        this.result.clientIds = [];
      }

      if (this.result && this.result.categoryType) {
        this.result.categoryType = null;
      }

      if (this.result && this.result.filteredData.length > 0) {
        this.result.filteredData = [];
      }

      if (this.result && this.result.fromMonth) {
        this.result.fromMonth = null;
      }

      if (this.result && this.result.toMonth) {
        this.result.toMonth = null;
      }

      if (this.result && this.result.trayNumbers) {
        this.result.trayNumbers = [];
      }

      if (this.isEbaReport){
        this.isBetweenOptions = true;
        this.defaultAnalysisType = ReportComparisonType.betweenHatchers;
        
        //reset days and weeks of flock dropdown
        this.daysOfFlockDropdown = [ { text: 'Day(s)', value: 'null'} ];
        this.weeksOfFlockDropdown = [ { text: 'Week(s)', value: 'null'} ];
        this.result.daysOfFlock = 'null';
        this.result.weeksOfFlock = 'null';

        if (this.result && this.result.trayNumbers.length > 0){
          this.result.trayNumbers = [];
        }
      }

      if (this.isPeaReport){
        //reset days and weeks of flock dropdown
        this.daysOfFlockDropdown = [ { text: 'Day(s)', value: 'null'} ];
        this.weeksOfFlockDropdown = [ { text: 'Week(s)', value: 'null'} ];
        this.result.daysOfFlock = 'null';
        this.result.weeksOfFlock = 'null';
      }

    }
  }

  // Init data in all store by fetchAll
  async created() {
    const species = (this.$router.currentRoute.fullPath.split('/'))[1];

    this.clientStore.setSpecies(species)
    await this.clientStore.fetchClients();
    await this.countryStore.fetchCountries();
    await this.countryStore.fetchStatesByCountryCode();
    this.clientNameData = this.clientsName;

    if (this.isEbaReport){
      this.isBetweenOptions = true;
      this.defaultAnalysisType = ReportComparisonType.betweenHatchers;
    }
  }

  get minMonth() {
    if (this.result.fromMonth) {
      const oneMonthAfter = moment(this.result.fromMonth).add(1, 'months');
      return oneMonthAfter;
    }

    return moment(new Date()).add(1, 'months');
  }

  get maxMonth() {
    if (this.result.fromMonth) {
      const oneYear = moment(this.result.fromMonth).add(11, 'months');
      return oneYear;
    }

    return moment(new Date()).add(11, 'months');
  }

  @Watch('result.fromMonth')
  clearMaxMonth() {
    if (this.result.fromMonth) {
      this.result.toMonth = null;
    }
  }

  get isCategoryTypeSelected() {
    return !this.result.categoryType;
  }

  get isFarmsSelected() {
    return !((this.defaultAnalysisType === ReportComparisonType.betweenHatchers || 
            this.defaultAnalysisType === ReportComparisonType.betweenPeaHatchers ||
            this.defaultAnalysisType === ReportComparisonType.betweenTrays) &&
            this.result.filteredData && this.result.filteredData.length > 0)
  }

  get disableDropdown() {
    return this.result.clientIds.length === 0;
  }

  // To get list of clients in dropdown filter
  get clients(): ClientResponse[] {
    return this.clientStore.clientsDropdown;
  }

  get isWaterReport(){
    return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.water;
  }

  get isInjectableReport(){
    return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.injectable;
  }

  get isHatcheryReport(){
    return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.hatchery;
  }

  get isCndReport(){
    return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.cnd;
  }

  get isIpmReport(){
    return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.ipm;
  }

  get isLowReport(){
    return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.labOnWheel;
  }

  get isEbaReport(){
    return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.eba;
  }

  get isPeaReport(){
    return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.pea;
  }

  get isBetweenPeaHatchersComparison(){
    return this.defaultAnalysisType === ReportComparisonType.betweenPeaHatchers;
  }

  get isBetweenEbaHatchersComparison(){ 
    return this.defaultAnalysisType === ReportComparisonType.betweenHatchers;
  }

  get isBetweenTraysComparison(){
    return this.defaultAnalysisType === ReportComparisonType.betweenTrays;
  }

  get isBetweenFarmsComparison(){
    return this.defaultAnalysisType === ReportComparisonType.betweenFarm;
  }

  get clientsName() {

    const clients = Object.values(this.clients).map((client) => {
      return {
        text: client.name,
        value: String(client.id),
      };
    });

    return [
      ...clients
    ];
  }

  // To get list of states in dropdown filter
  get regions(): Region[] {
    return this.countryStore.regions;
  }

  get regionsName() {
    return Object.values(this.regions).map(({ name, id}: any) => {
      return {
        text: name,
        value: id,
      };
    });
  }

  // To get list of farms in dropdown filter
  get farms(): FarmResponse[] {
    return this.farmDetailStore.farmsDropdown;
  }

  get farmsName() {
    return Object.values(this.farms).map((farm) => {
      return {
        text: farm.name,
        value: String(farm.id),
      };
    });
  }

  // To get list of auditors in dropdown filter
  get auditors(): AuditorResponse[] {
    return this.auditorDetailStore.auditorsDropdown;
  }

  get auditorsName() {
    return Object.values(this.auditors).map((auditor) => {
      return {
        text: auditor.userProfile.name,
        value: String(auditor.id),
      }
    });
  }

  resetResultsAnalysisCreated() {
    if (this.analysisStore.isCreated) {
      return this.analysisStore.setResultsCreated(false);
    }
  }

  // This watcher is to reset value in category and selection
  @Watch('analysisStore.clientName')
  clearCategoryAndSelections() {
    this.result.categoryType = null;
    this.resetSelectedData['selectedData'] = [];
  }

  // First Dropdown compare filter: Values are between_clients and between_farms/ between_complexes
  comparisonTypeChanged(comparisonType: DropdownOption) {
    this.resetResultsAnalysisCreated;

    if (this.result && this.result.clientIds) {
      this.result.clientIds = [];
    }

    if (this.result && this.result.categoryType) {
      this.result.categoryType = null;
    }

    if (this.result && this.result.filteredData.length > 0) {
      this.result.filteredData = [];
    }

    if (this.result && this.result.fromMonth) {
      this.result.fromMonth = null;
    }

    if (this.result && this.result.toMonth) {
      this.result.toMonth = null;
    }

    if ((this.isWaterReport || this.isInjectableReport || this.isHatcheryReport ||
        this.isCndReport || this.isIpmReport || this.isLowReport || this.isPeaReport) &&
        comparisonType.value === ReportComparisonType.betweenFarm){
      this.isBetweenOptions = true;
      this.defaultAnalysisType = comparisonType.value;
    }

    if ((this.isWaterReport || this.isInjectableReport || this.isHatcheryReport ||
        this.isCndReport || this.isIpmReport || this.isLowReport || this.isPeaReport) && 
        comparisonType.value === ReportComparisonType.betweenClient){
      this.isBetweenOptions = false;
      this.defaultAnalysisType = comparisonType.value;
    }

    if (this.isEbaReport && comparisonType.value === ReportComparisonType.betweenHatchers){
      this.isBetweenOptions = true;
      this.defaultAnalysisType = comparisonType.value;
      this.result.daysOfFlock = 'null';
      this.result.weeksOfFlock = 'null';
    }

    if (this.isEbaReport && comparisonType.value === ReportComparisonType.betweenTrays){
      this.isBetweenOptions = true;
      this.defaultAnalysisType = comparisonType.value;
      
      if (this.result && this.result.trayNumbers.length > 0){
        this.result.trayNumbers = [];
      }
    }

    if (this.isPeaReport && comparisonType.value === ReportComparisonType.betweenPeaHatchers){
      this.isBetweenOptions = true;
      this.defaultAnalysisType = comparisonType.value;
      this.result.daysOfFlock = 'null';
      this.result.weeksOfFlock = 'null';
    }

  }

  // First Dropdown filter: List of Clients
  handleClientChanged(params: DropdownOption) {
    this.resetResultsAnalysisCreated;
    this.activeSelectedDropdown['isActive'] = true;
    this.result.clientIds = [];
    this.analysisStore.setClientName(params);
    this.result.clientIds.push(params.value);
  }

  handleDaysOfFlockChanged(params: DropdownOption) {
    this.resetResultsAnalysisCreated;
    this.activeSelectedDropdown['isActive'] = true;
    this.result.daysOfFlock = params.value;
    if (params.value !== 'null'){
      this.analysisStore.setDaysOfFlock(Number(params.value));
    }
  }

  handleWeeksOfFlockChanged(params: DropdownOption) {
    this.resetResultsAnalysisCreated;
    this.activeSelectedDropdown['isActive'] = true;
    this.result.weeksOfFlock = params.value;
    
    if (params.value !== 'null'){
      this.analysisStore.setWeeksOfFlock(Number(params.value));
    }
  }

  // Second Dropdown filter: Values are Farm, Auditor and State
  handleCategoryTypeChanged(params: DropdownOption) {
    this.resetSelectedData['selectedData'] = [];
    this.clientDetailStore.setClientId(Number(this.result.clientIds.toString()));
    this.result.categoryType = params.value;
    if (params.value === 'farm') {
      this.farmDetailStore.fetchFarmsDropdown({
        clientId: this.result.clientIds[0],
        cities: [],
        state: '',
      });
    }
    if (params.value === 'auditor') {
      this.auditorDetailStore.fetchAuditorsDropdown(this.result.clientIds[0]);
    }
  }

  auditTypeChanged(auditType: DropdownOption) {
    this.resetResultsAnalysisCreated;
    if (auditType.value === '') {
      this.isAuditTypeSelected = false;
    } else {
      this.isAuditTypeSelected = true;
      this.result.auditType = auditType.value;
      this.result.sectionType = 'all';
      this.analysisStore.setAuditType(auditType.value);
    }
  }

  sectionTypeChanged(sectionType: DropdownOption) {
    this.resetResultsAnalysisCreated;
    if (sectionType.value === '') {
      this.isSectionTypeSelected = false;
    } else {
      this.isSectionTypeSelected = true;
      this.result.sectionType = sectionType.value;
      this.analysisStore.setSectionType(sectionType.value);
    }
  }

  lesionTypeChanged(lesionType: DropdownOption) {
    this.resetResultsAnalysisCreated;
    if (lesionType.value === '') {
      this.isLesionTypeSelected = false;
    } else {
      this.isLesionTypeSelected = true;
      this.result.lesionType = lesionType.value;
      this.analysisStore.setLesionType(lesionType.value);
    }
  }

  peaLesionTypeChanged(peaLesionType: DropdownOption) {
    this.resetResultsAnalysisCreated;
    if (peaLesionType.value === '') {
      this.isPeaLesionTypeSelected = false;
    } else {
      this.isPeaLesionTypeSelected = true;
      this.result.peaLesionType = peaLesionType.value;
      this.analysisStore.setPeaLesionType(peaLesionType.value);
    }
  }

  get ipmSectionType() {
    if (this.result.auditType === 'all')
      return this.ipmSectionDictionary.all;
    return this.ipmSectionDictionary[`${this.result.auditType}`];
  }

  // Third Dropdown filter: Watch if there are changes (selected) in State, Farm and Auditor Dropdown
  // then call the respective API for data listing in dropdown filter
  @Watch('result.categoryType')
  onChangeCategoryType() {
    if (this.result.categoryType === 'state') {
      return this.regionsName;
    }
    if (this.result.categoryType === 'farm' ) {
      return this.farmsName;
    }
    if (this.result.categoryType === 'auditor') {
      return this.auditorsName;
    }
  }

  filterClientName(filteredData: []) {
    this.result.clientIds = [...filteredData];
  }

  filterTrayNumber(filteredData: []) {
    this.result.trayNumbers = [...filteredData];

    if (filteredData && filteredData.length > 0){
      this.analysisStore.setTrayNumbers(this.result.trayNumbers);
    }
  }

  async searchByClientName(inputClientName: any) {
    // Filter the Client Objects here.
    if (!inputClientName.trim()) {
      this.clientNameData = this.clientsName;
    } else {
      await this.clientStore.searchByKeywords({
        keywords: inputClientName
      });

      this.clientNameData = this.clientStore.clients.map(client => {
        return {
          text: client.name,
          value: String(client.id)
        };
      });

    }
  }

  async searchByTrayNumber(inputTrayNumber: string) {
    if (inputTrayNumber.trim()) {
      this.trayNumberDropdown = [];

      this.analysisStore.setClientId(Number(this.result.clientIds[0]));
      this.analysisStore.setFarms(this.result.filteredData);
      this.analysisStore.setReportTypeId(this.reportTypeDetailStore.reportTypeId);

      await this.analysisStore.searchTrayNumbers(inputTrayNumber);

      if (this.analysisStore.trayNumbersDropdown.length > 0){
        this.analysisStore.trayNumbersDropdown.forEach((trayNumber) => {
          this.trayNumberDropdown.push({
            text: trayNumber,
            value: trayNumber
          });
        });
      }
    }
  }

  onCloseClientNameDropdown(selectedData: []) {
    this.result.clientIds = [...selectedData];
  }

  onCloseTrayNumberDropdown(selectedData: []) {
    this.result.trayNumbers = [...selectedData];
  }

  async filterCategoryName(filteredData: []) {
    this.result.filteredData = [...filteredData];

    //For EBA and PEA between hatchers, call age of flock api after filter farm
    if ((this.defaultAnalysisType === ReportComparisonType.betweenHatchers || 
        this.defaultAnalysisType === ReportComparisonType.betweenPeaHatchers) &&
        this.result.filteredData && this.result.filteredData.length > 0) {

        //reset days and weeks of flock dropdown
        this.daysOfFlockDropdown = [ { text: 'Day(s)', value: 'null'} ];
        this.weeksOfFlockDropdown = [ { text: 'Week(s)', value: 'null'} ];

        //set fetch ageOfFlock dropdown params
        this.analysisStore.setClientId(Number(this.result.clientIds[0]));
        this.analysisStore.setFarms(this.result.filteredData);
        this.analysisStore.setAnalysisType(this.defaultAnalysisType);
        this.analysisStore.setReportTypeId(this.reportTypeDetailStore.reportTypeId);

        //call ageOfFlock dropdown api
        await this.analysisStore.fetchAgeOfFlock();

        if (this.analysisStore.daysOfFlockDropdown.length > 0){
          this.analysisStore.daysOfFlockDropdown.forEach((day) => {
            this.daysOfFlockDropdown.push({
              text: String(day),
              value: String(day)
            });
          });
        }

        if (this.analysisStore.weeksOfFlockDropdown.length > 0){
          this.analysisStore.weeksOfFlockDropdown.forEach((week) => {
            this.weeksOfFlockDropdown.push({
              text: String(week),
              value: String(week)
            });
          });
        }
    }

    if (this.defaultAnalysisType === ReportComparisonType.betweenTrays &&
        this.result.filteredData && this.result.filteredData.length > 0){
          this.trayNumberDropdown = [];

          this.analysisStore.setClientId(Number(this.result.clientIds[0]));
          this.analysisStore.setFarms(this.result.filteredData);
          this.analysisStore.setReportTypeId(this.reportTypeDetailStore.reportTypeId);
          this.analysisStore.setAnalysisType(this.defaultAnalysisType);

          await this.analysisStore.fetchTrayNumbers();

          if (this.analysisStore.trayNumbersDropdown.length > 0){
            this.analysisStore.trayNumbersDropdown.forEach((trayNumber) => {
              this.trayNumberDropdown.push({
                text: trayNumber,
                value: trayNumber
              });
            });
          }
        }
  }

  onCloseCategoryTypeDropdown(selectedData: []) {
    this.result.filteredData = [...selectedData];
  }

  // When generate button is clicked
  handleGenerateReport() {
    this.analysisStore.setAnalysisType(this.defaultAnalysisType);
    this.analysisStore.setStartDate(moment(this.result.fromMonth!).format('YYYY-MM'));
    this.analysisStore.setEndDate(moment(this.result.toMonth!).format('YYYY-MM'));
    this.analysisStore.setClientIds(this.result.clientIds);
    this.analysisStore.setReportTypeId(this.reportTypeDetailStore.reportTypeId);
    this.analysisStore.setPeaLesionType(this.result.peaLesionType);
    this.analysisStore.setLesionType(this.result.lesionType);
    // TODO: Refactor
    if (this.result.categoryType === 'state') {
      this.analysisStore.setStates(this.result.filteredData);
    }
    if (this.result.categoryType === 'farm') {
      this.analysisStore.setFarms(this.result.filteredData);
    }
    if (this.result.categoryType === 'auditor') {
      this.analysisStore.setAuditors(this.result.filteredData);
    }
    //
    this.analysisStore.setCategoryType(this.result.categoryType);
    this.analysisStore.fetchAll();
  }

  get results() {
    return this.analysisStore.results;
  }

  @Watch('result')
  get dropdownIsValid(): boolean {
    const excludedKeys: string[] = [];

    // Push key that doesn't have in between clients to excludedkeys array
    if (this.defaultAnalysisType === ReportComparisonType.betweenClient) {
      excludedKeys.push('categoryType');
      excludedKeys.push('filteredData');
    }

    if (this.defaultAnalysisType !== ReportComparisonType.betweenTrays){
      excludedKeys.push('trayNumbers');
    }

    if (this.defaultAnalysisType !== ReportComparisonType.betweenHatchers && this.defaultAnalysisType !== ReportComparisonType.betweenPeaHatchers){
      excludedKeys.push('daysOfFlock');
      excludedKeys.push('weeksOfFlock');
    }

    // Filter keys that have same value as in the result object, exclude it and put it in new array
    const keys = Object.keys(this.result).filter((key) => excludedKeys.includes(key) === false);

    // Check each keys whether got values or empty
    const values = keys.map((key) => {
      // if no values in array of clientIds or in filteredData return false
      if (
        (key === 'clientIds' && !this.result[key].length) ||
        (key === 'filteredData' && !this.result[key].length) || 
        (key === 'trayNumbers' && !this.result[key].length)
      ) {
        return false;
      }

      if ((this.defaultAnalysisType === ReportComparisonType.betweenHatchers || this.defaultAnalysisType === ReportComparisonType.betweenPeaHatchers) && 
          ((key === 'daysOfFlock' && this.result[key] === 'null') || (key === 'weeksOfFlock' && this.result[key] === 'null') )){
        return false;
      }

      return this.result[key];
    });
    return values.every(Boolean);
  }
}
